import React from 'react';

const BackDrop = (props) => {
    const {drawerToggleClickHandler} = props
    return (
        <div className="backdrop" onClick={drawerToggleClickHandler}>
            
        </div>
    );
};

export default BackDrop;