import { combineReducers } from "redux";
import authReducer from "./authReducer";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import Constants from "../constants/index";

const appReducer = combineReducers({
  authReducer,
});

const rootReducer = (state, action) => {
  if (action.type === Constants.LOG_OUT) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;