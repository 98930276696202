import React, { useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { subscribeAction } from "../reduxStore/actions/subscribeAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CgUnavailable } from "react-icons/cg";
import moment from "moment";
import { BsFillSquareFill } from "react-icons/bs";

const CheckoutForm = ({ handlePayment, subscriptionData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { user } = useSelector(({ authReducer }) => authReducer);

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#ffffff",
        border: "1px solid balck",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#6C757D",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: user.email,
      },
    });
    if (!error) {
      dispatch(
        subscribeAction(
          {
            paymentMethodId: paymentMethod.id,
            description: subscriptionData.description,
            amount: subscriptionData.amount,
            email: user.email,
            name: `${user.firstname} ${user.lastname}`,
            plan: subscriptionData.plan,
          },
          handlePayment
        )
      );
    } else {
      setLoading(false);
      console.log(error, "error");
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card my-5">
        <div className="card-body">
          <div className="position-relative">
            <div
              className="position-absolute btn"
              onClick={handlePayment}
              style={{ right: "0", color: "#ffffff" }}
            >
              <ImCancelCircle />
            </div>
            <h4 className="card-title">{subscriptionData.name}</h4>
            <h5 className="card-title">{subscriptionData.subAmount}</h5>
            <p className="card-text">{subscriptionData.type}</p>
          </div>
          <hr />
          <CardElement options={CARD_OPTIONS} />
          <hr />
          <button
            className="btn btn-warning"
            type="submit"
            disabled={!stripe || !elements || loading}
          >
            Pay{loading && "ing..."}
          </button>
        </div>
      </div>
    </form>
  );
};

const Pricing = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState("pricing");
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const { user } = useSelector(({ authReducer }) => authReducer);
  // const isSubscribed = user.data.finance.subscription.isSubscribed;
  const plan = user.data.finance.subscription.plan;
  const nameOfPlan = user.data.finance.subscription.nameOfPlan;
  const subStatus = user.data.finance.status;

  const stripePromise = loadStripe(
    // "pk_test_51JbqdkIXhXLiLEqIH3rvGLJGXPlaGjEzibinGxiKOEK31e2eqTZi69miNW1K9SvLKVGtp744pfLZtgixGZObakYM00MeJ1hEAF"
    "pk_live_51JbqdkIXhXLiLEqIXEoD5hCXY2YjolkJ9Ou4SX8yYnjkgSxi3zHRB6tINol4eHEzvDuDJrJsnOX7cQZpFpNssbTH0001JM8TS4"
  );

  const handlePayment = (data) => {
    setSubscriptionData(data);
    setShow("cardPayment");
  };

  const handleFreeSub = (subData) => {
    setLoading(true);
    dispatch(
      subscribeAction(
        {
          paymentMethodId: "",
          description: subData.description,
          amount: subData.amount,
          email: user.email,
          name: `${user.firstname} ${user.lastname}`,
          plan: subData.plan,
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  const getTotalSubAccount = (subType) => {
    switch (subType) {
      case "monthly":
        return "9.90 € / month";
      case "yearly":
        return "99.0 € / year";
      case "free":
        return "0.0 €";
      default:
        return "";
    }
  };

  //     const { error, paymentMethod } = await stripe.createPaymentMethod({
  //       type: "card",
  //       card: elements.getElement(CardElement),
  //     });
  //   };

  console.log(plan, "na plan i dey find");

  return (
    <div className="container">
      {show === "pricing" && (
        <>
          <div className="row my-5">
            <div className="col-md-4 mb-5">
              <div className="olto_theme_card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">OLTO Trading Mentor</h4>
                    <p className="card-text">
                      {nameOfPlan === "14DayTrial"
                        ? "Free 14-day Trial"
                        : nameOfPlan === "freemium"
                        ? "Freemium"
                        : nameOfPlan === "fullVersion"
                        ? "Freemium"
                        : "Free 14-day Trial"}
                    </p>
                    {/* <p className="card-text">Freemium</p> */}
                    <h5 className="card-title">0 € monthly</h5>
                    <small>(no credit card required)</small>
                  </div>
                  <ul className="list-group list-group-flush pricing_list">
                    <li className="list-group-item d-flex align-items-center">
                      <h6>OLTO Trading Ideas</h6>
                    </li>
                    {/* <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      up to 30 ideas per week in real time (14-day TRIAL)
                    </li> */}
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      <p>
                        {nameOfPlan === "14DayTrial"
                          ? "up to 30 ideas per week in real time"
                          : nameOfPlan === "freemium"
                          ? "up to 6 ideas per week with a 3 hour delay"
                          : nameOfPlan === "fullVersion"
                          ? "up to 6 ideas per week with a 3 hour delay"
                          : "up to 30 ideas per week in real time"}
                        <br />
                        {nameOfPlan === "14DayTrial" && (
                          <small>(14-day TRIAL)</small>
                        )}
                      </p>
                    </li>
                  </ul>
                  <ul className="list-group list-group-flush pricing_list">
                    <li className="list-group-item d-flex align-items-center">
                      <h6>OLTO Trading Course</h6>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Basic course - Trading terms and a systematic approach
                    </li>
                    {/* <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Lifetime access
                    </li> */}
                  </ul>
                  <div className="card-body text-center">
                    <button
                      disabled={plan === "free" || loading}
                      onClick={() => {
                        handleFreeSub({
                          name: "OLTO Trading Mentor",
                          amount: 0,
                          type: "14 days Trial",
                          subAmount: "0 €",
                          description: "OLTO Trading Mentor",
                          plan: "free",
                        });
                      }}
                      type="button"
                      className="btn btn-lg primary_button btn-dark px-5 "
                      // disabled={loading}
                    >
                      <b>
                        {plan === "free"
                          ? "Current Active Plan"
                          : `${loading ? "Subscribing..." : "Start Now"}`}
                      </b>
                      {/* <b>{`${loading?"Subscribing...":"Start Now"}`}</b> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4 mb-5">
              <div className="olto_theme_card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">OLTO Trading Mentor</h4>
                    <p className="card-text">Full version</p>
                    <h5 className="card-title">1.00 € monthly</h5>
                  </div>
                  <ul className="list-group list-group-flush pricing_list">
                    <li className="list-group-item d-flex align-items-center">
                      <h6>OLTO Trading Ideas</h6>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      up to 120 ideas per month
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Trading Ideas in real time
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6>OLTO Trading Course</h6>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Basic course - Trading terms and a systematic approach
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Pro course - The practical guide to making a good trade
                    </li>
                  </ul>
                  <div className="card-body text-center">
                    <button
                      disabled={plan === "monthly"}
                      onClick={() => {
                        handlePayment({
                          name: "OLTO Trading Mentor",
                          amount: 100,
                          type: "Test Card",
                          subAmount: "1.00 € / month",
                          description: "OLTO Trading Mentor",
                          plan: "monthly",
                        });
                      }}
                      type="button"
                      className="btn btn-lg primary_button btn-dark px-5 "
                    >
                      <b>
                        {plan === "monthly"
                          ? "Current Active Plan"
                          : "Start Now"}
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-4 mb-5">
              <div className="olto_theme_card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">OLTO Trading Mentor</h4>
                    <p className="card-text">Full version</p>
                    <h5 className="card-title">9.90 € monthly</h5>
                  </div>
                  <ul className="list-group list-group-flush pricing_list">
                    <li className="list-group-item d-flex align-items-center">
                      <h6>OLTO Trading Ideas</h6>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      up to 120 ideas per month
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Trading Ideas in real time
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6>OLTO Trading Course</h6>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Basic course - Trading terms and a systematic approach
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Pro course - The practical guide to making a good trade
                    </li>
                  </ul>
                  <div className="card-body text-center">
                    <button
                      disabled={plan === "monthly"}
                      onClick={() => {
                        handlePayment({
                          name: "OLTO Trading Mentor",
                          amount: 990,
                          type: "Full version",
                          subAmount: "9.90 € / month",
                          description: "OLTO Trading Mentor",
                          plan: "monthly",
                        });
                      }}
                      type="button"
                      className="btn btn-lg primary_button btn-dark px-5 "
                    >
                      <b>
                        {plan === "monthly"
                          ? "Current Active Plan"
                          : "Start Now"}
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div className="olto_theme_card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">OLTO Trading Mentor</h4>
                    <p className="card-text">Full version</p>
                    <h5 className="card-title">99 € annual</h5>
                  </div>
                  <ul className="list-group list-group-flush pricing_list">
                    <li className="list-group-item d-flex align-items-center">
                      <h6>OLTO Trading Ideas</h6>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      up to 120 ideas per month
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Trading Ideas in real time
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <h6>OLTO Trading Course</h6>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Basic course - Trading terms and a systematic approach
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                      <div>
                        <BsFillSquareFill
                          size={8}
                          className="mr-3 theme_color"
                        />
                      </div>
                      Pro course - The practical guide to making a good trade
                    </li>
                  </ul>
                  <div className="card-body text-center">
                    <button
                      disabled={plan === "yearly"}
                      onClick={() => {
                        handlePayment({
                          name: "OLTO Trading Mentor",
                          amount: 9900,
                          type: "Full version",
                          subAmount: "99.0 € / year",
                          description: "OLTO Trading Mentor",
                          plan: "yearly",
                        });
                      }}
                      type="button"
                      className="btn btn-lg primary_button btn-dark px-5 "
                    >
                      <b>
                        {plan === "yearly"
                          ? "Current Active Plan"
                          : "Start Now"}
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="table-responsive">
                <table className="table">
                  <caption>Account Plan Subscription</caption>
                  {user.data.finance.subscription.isSubscribed && (
                    <tbody>
                      <tr>
                        <td>Total</td>
                        <td>
                          {getTotalSubAccount(
                            user.data.finance.subscription.plan
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Current Active Plan</td>
                        <td>
                          {nameOfPlan === "14DayTrial" &&
                            "Trading Mentor Free 14-day Trial"}
                          {nameOfPlan === "freemium" &&
                            "Trading Mentor Freemium"}
                          {nameOfPlan === "fullVersion" &&
                            "Trading Mentor Full version"}
                        </td>
                      </tr>
                      {plan !== "free" &&
                        user.data.finance.subscription.receiptUrl && (
                          <tr>
                            <td>Payment Receipt</td>
                            <a
                              href={user.data.finance.subscription.receiptUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <td
                                className="text-danger cursor_pointer"
                                style={{ textDecoration: "underline" }}
                              >
                                View Receipt
                              </td>
                            </a>
                          </tr>
                        )}
                      <tr>
                        <td>Subscription Period Start</td>
                        <td>
                          {moment(
                            user.data.finance.subscription.startDate
                          ).format("Do MM YYYY, h:mm:ss:a")}
                        </td>
                      </tr>
                      {((plan === "free" &&
                        !user.data.finance.subscription.used14DayTrial) ||
                        plan !== "free") && (
                        <tr>
                          <td>Subscription Period End</td>
                          <td>
                            {moment(
                              user.data.finance.subscription.endDate
                            ).format("Do MM YYYY, h:mm:ss:a")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                  {!user.data.finance.subscription.isSubscribed && (
                    <div className="text-center">
                      <CgUnavailable size="100px" />
                      <h4 className="mt-3">
                        {" "}
                        You dont have an active Subscription plan
                      </h4>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      {show === "cardPayment" && (
        <div className="row mb-5">
          <div className="col-md-6 mx-auto">
            <Elements stripe={stripePromise}>
              <CheckoutForm
                handlePayment={() => {
                  setShow("pricing");
                }}
                subscriptionData={subscriptionData}
              />
            </Elements>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pricing;
