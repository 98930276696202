import React from "react";
import { CgFeed } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { RiLogoutBoxLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { FaSignal } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logOut } from "../reduxStore/actions/authAction";
import { Link, NavLink } from "react-router-dom";

const SideDrawer = (props) => {
  const { sideDrawerOpen } = props;
  const dispatch = useDispatch();
  const logOutUser = () => {
    dispatch(logOut());
    props.children.props.history.push("/");
  };
  return (
    <nav className={`side-drawer ${sideDrawerOpen && "open"}`}>
      <div className="toolbar__logo d-md-block d-none ml-5 mt-4">
        <a href="/pricing">OLTO</a>
      </div>
      <ul>
        {/* <li className="mb-3">
          <NavLink to="/signals" activeClassName="acive_nav">
            <FaSignal className="sideDrawer_icon side_nav" />
            Signals
          </NavLink>
        </li> */}
        {/* <li className="mb-3">
          <NavLink to="/home" activeClassName="acive_nav">
            {" "}
            <CgFeed className="sideDrawer_icon" />
            Feeds
          </NavLink>
        </li> */}
        {/* <li className="mb-3"></li> */}
        {/* <li className="mb-3">
          <a href="/profile/setting">
            <FiSettings className="sideDrawer_icon side_nav" />
            Setting
          </a>
        </li> */}
        <li className="mb-3">
          <NavLink to="/pricing" activeClassName="acive_nav">
            <IoPricetagsOutline className="sideDrawer_icon side_nav" />
            Pricing
          </NavLink>
        </li>
        {/* <li className="mb-3">
          <NavLink to="/tutorial" activeClassName="acive_nav">
            <IoPricetagsOutline className="sideDrawer_icon side_nav" />
            Trade Courses
          </NavLink>
        </li> */}
        {/* <li className="cursor_pointer side_nav" onClick={logOutUser}>
          <RiLogoutBoxLine className="sideDrawer_icon" />
          Logout
        </li> */}
      </ul>
    </nav>
  );
};

export default SideDrawer;
