import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BackDrop from "./BackDrop";
// import Messages from "./Messages";
import SideDrawer from "./SideDrawer";
import ToolBar from "./ToolBar";

const Layout = (props) => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(true);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { user } = useSelector(({ authReducer }) => authReducer);

  const drawerToggleClickHandler = () => {
    // setSideDrawerOpen((prevState) => {
    //   return !prevState;
    // });
    setShowBackDrop((prevState) => {
      return !prevState;
    });
  };

  useEffect(() => {
    if (!user?.token) {
      props.children.props.history.push("/");
    }
  }, [props, user]);

  return (
    <div className="container-fluid  main_layout_wrapper">
      <ToolBar drawerToggleClickHandler={drawerToggleClickHandler} {...props} />
      {/* <Messages /> */}
      <div className="d-md-block d-none">
        <SideDrawer sideDrawerOpen={sideDrawerOpen} {...props} />
      </div>
      {sideDrawerOpen && showBackDrop && (
        <>
          <SideDrawer sideDrawerOpen={sideDrawerOpen} {...props} />
          <BackDrop drawerToggleClickHandler={drawerToggleClickHandler} />
        </>
      )}
      <div className="row layout_container" style={{ marginTop: "70px" }}>
        <div className="col-12">{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
