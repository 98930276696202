const BaseUrl = "https://immense-dawn-05518.herokuapp.com";
// const BaseUrl = "http://localhost:8080";
const getResponseBaseUrl = "https://api.getresponse.com/v3";
const getResponseBaseUrl1 = "https://api3.getresponse360.pl/v3";

export const endPoints = {
  userRegistration: BaseUrl + "/users/signup",
  userLogin: BaseUrl + "/users/login",
  subscribe: BaseUrl + "/subscribe",
  createFormField: getResponseBaseUrl + "/from-fields",
  campaigns: getResponseBaseUrl1 + "/campaigns",
  getUserDetails: BaseUrl + "/users/auth/verify-token?token=true",
  googleLogin: BaseUrl + "/users/auth/google/callback",
  profileSettings: BaseUrl + "/users/profile/bio",
  profilePictureSettings: BaseUrl + "/users/profile/picture",
  forgotPassword: BaseUrl + "/users/forgot-password",
  passwordReset: BaseUrl + "/users/reset-password",
  logoImgLink:
    BaseUrl +
    "https://uc57a0af53c7b9009d1c4a33c656.previews.dropboxusercontent.com/p/thumb/ABWqdd3-h1AA3PcTRzpy9BL8XZn5dfYXM7FIxgCA_TG7kPpjZTTDPnlIURJemiiUom7OXAhAl4a38cvNVJQ4L68vKDcWGtRjKXF9H-F3JJZ0zQl-BvwWAfjfCNxP6FPHfz4yvURNt-YIKa8LI748r_auhyb5wL1_i3DtQt1tHM2tXqiZCQgK52MJSp30_xHq_0ZH3MtvJeuaBNyPbJ-9NRUGrm8rOtGkdk3REtxTB_3a2eUNSmfNyhjpyBnpXmRpXNNdEKQ7F73FMGbYYa6hTQymhqxMotNp3LzRyJBHjZhELsVsHeq2YfAZGijJcdK8g6LcDn3eOWPc6iY1n4hjFZHpquys9hTLF4GOc0s8piTevx0PnIpPxTfQ9WARbzVWE18ruPYE3L9AGLa3aCobNmRq/p.png?fv_content=true&size_mode=5",
};
