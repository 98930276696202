import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

// AiOutlineClose

const Modal = ({ modalTrigger, modalBody, modalTittle = "" }) => {
  return (
    <>
      <div data-toggle="modal" data-target="#exampleModal">
        {modalTrigger}
      </div>

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
          <div className="modal-content custom_modal_style"> 
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{modalTittle}</h5>
              <button type="button" className="close close_modal_icon" data-dismiss="modal" aria-label="Close">
                <AiOutlineClose/>
              </button>
            </div>
            <div className="modal-body">
              {modalBody}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;