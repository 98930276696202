import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { endPoints } from "../endpoint";

const ChangePassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(endPoints.passwordReset, password)
      .then((res) => {
        toast.success(`Password reset complete. You can login now`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          props.history.push("/");
        }, 3000);
      })
      .catch((err) => {
        toast.success(`Ooops! Something went wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    setLoading(true);
  };

  useEffect(() => {
    const token = new URLSearchParams(props.location.search).get("token");
    setPassword({ token: token });
  }, [props]);

  return (
    <div className="container-fluid authBgImage">
      <div className="row h-100">
        <div className="col-md-5 mx-auto d-flex align-items-center ">
          <div className="card w-100 p-4 auth_card">
            <div className="card-header text-center mb-3">
              <h1>Welcome to OLTO</h1>
              <h6>Please add your password</h6>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password.password}
                      onChange={(e) => {
                        setPassword({ ...password, password: e.target.value });
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      value={password.confirmPassword}
                      onChange={(e) => {
                        setPassword({
                          ...password,
                          confirmPassword: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-lg btn-dark primary_button px-5 w-100"
                  >
                    <b>{loading ? "Verifying..." : "Authenticate"}</b>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
