import React, { useState } from 'react';

const TwoVerifyForm = (props) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true)
        setTimeout(() => {
            props.history.push('/home')
        }, 3000);
    }
    return (
        <div className="container-fluid authBgImage">
            <div className="row h-100">
                <div className="col-md-5 mx-auto d-flex align-items-center ">
                    <div className="card w-100 p-4 auth_card">
                        <div className="card-header text-center mb-3">
                            <h1>Welcome to OLTO</h1>
                            <h6>Please add the OTP sent to you</h6>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="One time password(OTP)" />
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <button disabled={loading} type="button" className="btn btn-lg primary_button btn-dark px-5 w-100" onClick={handleSubmit}><b>{loading ? "Verifying..." : "Authenticate"}</b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoVerifyForm;