import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { endPoints } from "../endpoint";
import Avatar from "react-avatar";
import { fetchUserDetails } from "../reduxStore/actions/authAction";

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ authReducer }) => authReducer);
  const [values, setValues] = useState({
    email: "",
    firstname: "",
    lastname: "",
    newPassword: "",
    oldPassword: "",
    contactNumber: "",
    location: {
      city: "",
      state: "",
      country: "",
      zipCode: "",
      home: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  const updateProfileDeatails = (e) => {
    const dataToPost = { ...values };
    delete dataToPost.email;
    e.preventDefault();
    setLoading(true);
    axios
      .patch(endPoints.profileSettings, dataToPost, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(fetchUserDetails());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleFileChange = (e) => {
    var formData = new FormData();
    const file = e.target.files[0];
    formData.append("file", file);
    axios
      .patch(endPoints.profilePictureSettings, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        toast.success(`Changed profile picture`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setImgUrl(res.data.user.profileImageUrl);
        dispatch(fetchUserDetails());
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    setValues({
      email: user.data.email,
      firstname: user.data.firstname,
      lastname: user.data.lastname,
      newPassword: "",
      oldPassword: "",
      contactNumber: user.data.contactNumber,
      location: {
        city: user.data.location.city,
        state: user.data.location.state,
        country: user.data.location.country,
        zipCode: user.data.location.zipCode,
        home: user.data.location.home,
      },
    });
    setImgUrl(user.data.profileImageUrl);
  }, [user]);

  return (
    <div className="row">
      <div className="col-md-6 mx-auto">
        <h3 className="my-5">Edit Profile</h3>
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="profile_image position-relative">
              <input
                type="file"
                id="actual-btn"
                hidden
                onChange={handleFileChange}
              />
              <Avatar
                name={`${user.data.firstname} ${user.data.lastname}`}
                src={imgUrl}
                size="200px"
                round="100px"
              />
              {/* <img src={dummyImg} alt="..." width="100%" height="100%" /> */}
              <label
                for="actual-btn"
                className="d-flex align-items-center image_edit_icon justify-content-center"
              >
                <MdEdit style={{ fontSize: "22px" }} />
              </label>
            </div>
          </div>
        </div>
        <form onSubmit={updateProfileDeatails}>
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="mb-1">First Name</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  required
                  value={values.firstname}
                  className="form-control"
                  onChange={(e) => {
                    setValues({ ...values, firstname: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-1">Last Name</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  required
                  value={values.lastname}
                  className="form-control"
                  onChange={(e) => {
                    setValues({ ...values, lastname: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-1">Email</div>
              <div className="input-group mb-3">
                <input
                  type="email"
                  disabled
                  value={values.email}
                  className="form-control"
                  onChange={(e) => {
                    setValues({ ...values, email: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-1">Contact Number</div>
              <div className="input-group mb-3">
                <input
                  value={values.contactNumber}
                  type="number"
                  className="form-control"
                  onChange={(e) => {
                    setValues({ ...values, contactNumber: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-1">Address</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={values.location.home}
                  className="form-control"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      location: { ...values.location, home: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-1">City</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={values.location.city}
                  className="form-control"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      location: { ...values.location, city: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="mb-1">State</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={values.location.state}
                  className="form-control"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      location: { ...values.location, state: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-1">Zip code</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={values.location.zipCode}
                  className="form-control"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      location: { ...values.location, zipCode: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="mb-1">Country</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={values.location.country}
                  className="form-control"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      location: { ...values.location, country: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col">
              <h4>Change password</h4>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-1">Old Password</div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  autoComplete="off"
                  value={values.oldPassword}
                  className="form-control"
                  onChange={(e) => {
                    setValues({ ...values, oldPassword: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-1">New Password</div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  autoComplete="off"
                  value={values.newPassword}
                  className="form-control"
                  onChange={(e) => {
                    setValues({ ...values, newPassword: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              <button
                disabled={loading}
                type="submit"
                // onClick={}
                className="btn btn-lg primary_button btn-dark px-5 "
              >
                <b>Save</b>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileSetting;
