import React, { useState } from "react";
import { signupAction } from "../reduxStore/actions/authAction";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { endPoints } from "../endpoint";
import signuBtn from "../assets/images/Fixed 2.png";

const SignupCard = (props) => {
  const { setActive, signupAction } = props;
  const [loading, setLoading] = useState(false);
  const [acceptedTermsAndCondition, setAcceptedTermsAndCondition] =
    useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!acceptedTermsAndCondition) {
      toast.error("Please accept terms and condition to proceed", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    setLoading(true);
    if (userDetails.password !== confirmPassword) {
      toast.error("Password does not match", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      return;
    }
    const req = await signupAction(userDetails);
    if (req.status) {
      setTimeout(() => {
        props.history.push("/pricing");
      }, 3000);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="card w-100 p-4 auth_card">
      {/* <ToastContainer /> */}
      <div className="card-header text-center">
        <h2>Welcome to OLTO Trade Box</h2>
        <h6>Sign up your account</h6>
      </div>
      <div className="card-body">
        <div className="col-12 text-center">
          <a href={endPoints.googleLogin}>
            <button className="btn socio_auth_btn p-0 mb-4">
              <img src={signuBtn} alt="..." width="100%" />
            </button>
          </a>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="input-group mb-3">
                <input
                  required
                  type="text"
                  className="form-control"
                  value={userDetails.firstname}
                  placeholder="First name"
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      firstname: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            {/* <div className="col-6">
              <div className="input-group mb-3">
                <input
                  required
                  type="text"
                  className="form-control"
                  value={userDetails.lastname}
                  placeholder="Last name"
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      lastname: e.target.value,
                    });
                  }}
                />
              </div>
            </div> */}
            <div className="col-12">
              <div className="input-group mb-3">
                <input
                  required
                  type="email"
                  value={userDetails.email}
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, email: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-group mb-3">
                <input
                  required
                  type="password"
                  className="form-control"
                  value={userDetails.password}
                  placeholder="Password"
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-group mb-3">
                <input
                  required
                  type="password"
                  className="form-control"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setAcceptedTermsAndCondition(e.target.checked);
                  }}
                />
                <small className="my-0 ml-2">
                  I agree to OLTO'S{" "}
                  <a
                    href="https://www.olto.space/terms-of-use"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of use
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.olto.space/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>
                </small>
              </div>
            </div>
            <div className="col-12 mb-2">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-lg btn-dark primary_button px-5 w-100 mb-2"
                onSubmit={handleSubmit}
              >
                <b>{loading ? "Authenticating..." : "Sign up"}</b>
              </button>
              <p style={{ color: "#939CA4" }}>Forgot password?</p>
              <p>
                Already have an account?{" "}
                <span className="cursor_pointer links" onClick={setActive}>
                  Login here
                </span>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { signupAction })(SignupCard);
