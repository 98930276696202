import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { endPoints } from "../endpoint";

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(endPoints.forgotPassword,{email})
      .then((res) => {
        toast.success(`Check your email`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
            setLoading(false);
          props.history.push("/");
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Ooops! Something went wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <div className="container-fluid authBgImage">
      <div className="row h-100">
        <div className="col-md-5 mx-auto d-flex align-items-center ">
          <div className="card w-100 p-4 auth_card">
            <div className="card-header text-center mb-3">
              <h1>Welcome to OLTO</h1>
              <h6>Please add your email</h6>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-lg btn-dark primary_button px-5 w-100"
                  >
                    <b>{loading ? "Loading..." : "Recover Password"}</b>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
