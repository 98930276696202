import React from "react";
import dummyImg from "../assets/images/pexels-anna-nekrashevich-6801648.jpg";
import MiniChart from "react-mini-chart";

const Signals = () => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center my-4">
        <h3>Signal catalog</h3>
        <div className="d-flex align-items-center">
          <div className="dropdown mr-3">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              Dropdown
            </button>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenu2"
            >
              <button className="dropdown-item" type="button">
                Action
              </button>
              <button className="dropdown-item" type="button">
                Another action
              </button>
              <button className="dropdown-item" type="button">
                Something else here
              </button>
            </div>
          </div>
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenu3"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              Dropdown
            </button>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenu3"
            >
              <button className="dropdown-item" type="button">
                Action
              </button>
              <button className="dropdown-item" type="button">
                Another action
              </button>
              <button className="dropdown-item" type="button">
                Something else here
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <caption>Signal catalog</caption>
          <thead>
            <tr>
              <th>NAME</th>
              {/* <th>PROFIT</th> */}
              <th>SUBSCRIPTION STATUS</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6].map((x, i) => (
              <tr key={i} className="py-3">
                <td style={{ width: "80%" }}>
                  <div className="d-flex align-items-center">
                    <img src={dummyImg} alt="..." className="signal_img mr-3" />
                    <div className="border-0">
                      Bit coin Gold (BTG)
                      <br />
                      <small>BTG</small>
                    </div>
                  </div>
                </td>
                {/* <td>
                  <div className="d-flex align-items-center">
                    <MiniChart
                      strokeColor="#E21C1B"
                      activePointColor="#E21C1B"
                      activePointRadius={8}
                      strokeWidth={2}
                      labelFontSize={10}
                      width={100}
                      height={50}
                      dataSet={[0, -20, 343, 49.3, -100, 200, 78]}
                    />
                    <div className="border-0 ml-3" style={{ color: "#E21C1B" }}>
                      + 16,86 $
                      <br />
                      <small>47,98%</small>
                    </div>
                  </div>
                </td> */}
                <td className="text-center">
                    <button className="btn mb-md-0 px-5 primary_button">Subscribe</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Signals;
