// import Constants from "../constants/index";
import Axios from "axios";
import { endPoints } from "../../endpoint";
import { toast } from "react-toastify";
import { fetchUserDetails } from "./authAction";

const subscribeAction =
  (paymentData, handlePayment) => (dispatch, getstate) => {
    const token = getstate().authReducer.user.token;
    return Axios.post(endPoints.subscribe, paymentData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(fetchUserDetails())
        setTimeout(() => {
          handlePayment();
        }, 3000);
        return { status: true, message: "message" };
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          handlePayment();
        }, 3000);
        return {
          status: false,
        };
      });
  };

export { subscribeAction };
