const Constants = {
  AUTH_ERROR: "AUTH_ERROR",
  USER_DETAILS: "USER_DETAILS",
  ROLE: "ROLE",
  LOG_OUT: "LOG_OUT",
  RECRUITERS: "RECRUITERS",
  ORGANIZATION: "ORGANIZATION",
  ORGANIZATION_ROLE: "ORGANIZATION_ROLE",
};

export default Constants;
