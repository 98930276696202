import React from "react";

const _$ = window.jQuery;

const TradeCourseModal = ({ trigger, content }) => {
  const closeModal = () => {
    // document.getElementById('courseVideo').pause()
    _$("#exampleModal").on("hidden.bs.modal", function (e) {
      _$("#exampleModal iframe").attr("src", _$("iframe").attr("src"));
    });
  };
  console.log(_$, "csdcsd");
  return (
    <div className="h-100 w-100">
      <div
        className="btn w-100 h-100"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        {trigger}
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        // data-backdrop="static"
        onClick={closeModal}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            {/* <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div className="modal-body p-0" style={{ height: "500px" }}>
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeCourseModal;
