import React from 'react';
// import { useDispatch } from 'react-redux';
import Trades from '../components/Trades';
// import { getCampains } from '../reduxStore/actions/getResponseAction';

const HomePage = () => {
    // const dispatch = useDispatch()
    // useEffect(()=>{
    //     dispatch(getCampains());
    // },[])
    return (
        <>
            {[1, 2, 3, 4, 4].map(() => (
                <Trades />
            ))}
        </>
    );
};

export default HomePage;