import React, { useState } from "react";
import { loginAction } from "../reduxStore/actions/authAction";
import { connect } from "react-redux";
import { endPoints } from "../endpoint";
import { Link } from "react-router-dom";
import loginBtn from "../assets/images/Fixed.png";

const LoginCard = (props) => {
  const { setActive, loginAction } = props;
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const req = await loginAction(userDetails);
    if (req.status) {
      setTimeout(() => {
        props.history.push("/pricing");
      }, 3000);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="card  w-100 p-4 auth_card">
      <div className="card-header text-center">
        <h2>Welcome to OLTO Trade Box</h2>
        <h6>Sign into your account</h6>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 text-center">
            <a href={endPoints.googleLogin}>
              <button className="btn socio_auth_btn p-0 mb-4">
                <img src={loginBtn} alt="..." width="100%" />
              </button>
            </a>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="input-group mb-3">
                <input
                  required
                  type="email"
                  value={userDetails.email}
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, email: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-group mb-3">
                <input
                  required
                  type="password"
                  value={userDetails.password}
                  className="form-control"
                  placeholder="Password"
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-12 mb-2">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-lg btn-dark primary_button px-5 w-100 mb-2"
                // onClick={handleSubmit}
              >
                <b>{loading ? "Authenticating ..." : "Log in"}</b>
              </button>
              {/* /forgot-password */}
              <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                <p style={{ color: "#939CA4" }}>Forgot password?</p>
              </Link>
              <p>
                Don't have an account?{" "}
                <span className="cursor_pointer links" onClick={setActive}>
                  Sign up
                </span>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { loginAction })(LoginCard);
