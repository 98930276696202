import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from "react-router-dom";
import Layout from './components/Layout';
import AuthPage from './pages/AuthPage';
import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import HomePage from './pages/HomePage';
import Pricing from './pages/Pricing';
import ProfileSetting from './pages/ProfileSetting';
import RedirectView from './pages/RedirectView';
import Signals from './pages/Signals';
import TradeCoursesPage from './pages/TradeCoursesPage';
import TwoVerifyForm from './pages/TwoVerifyForm';




const AppRouter = ({ Component, path, exact, purpose, auth, ...rest }) => {
    return (
        <Route exact={exact} path={path} {...rest} render={(props) => {
            return (
                <Layout>
                    <Component {...rest} {...props} />
                </Layout>
            )
        }} />
    )
}

const AppRoute = () => {
    return (
        <Router>
            <Switch>
                {/* <Route path="/home" component={HomePage} exact/> */}
                <Route path="/" component={AuthPage} exact/>
                <Route path="/redirect" component={RedirectView} exact/>
                <Route path="/verify" component={TwoVerifyForm} exact/>
                <Route path="/forgot-password" component={ForgotPassword} exact/>
                <Route path="/reset-password" component={ChangePassword} exact/>
                <AppRouter Component={HomePage} path="/home" exact={true} />
                <AppRouter Component={ProfileSetting} path="/profile/setting" exact={true} />
                <AppRouter Component={Pricing} path="/pricing" exact={true} />
                <AppRouter Component={TradeCoursesPage} path="/tutorial" exact={true} />
                <AppRouter Component={Signals} path="/signals" exact={true} />
                {/* <AppRouter Component={Messages} path="/messages" exact={true} /> */}
            </Switch>
        </Router>
            
        
    )
}

export default AppRoute