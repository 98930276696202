import React from "react";
import { GiArchiveResearch } from "react-icons/gi";
import TradeCourseModal from "../components/TradeCourseModal";
import styles from "../styles/tradeCourse.module.css";

const TradeCoursesPage = () => {
  return (
    <div>
      <div className="row mt-4 mb-5">
        <div className="col-md-8 mx-auto">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span
                className="input-group-text theme_bg_color border-0"
                id="basic-addon1"
              >
                <GiArchiveResearch />
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search for course"
              aria-label="Search for course"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
      </div>
      <div className="row">
        {[1, 2, 3, 4, 5, 6].map(() => (
          <div className="col-md-6 my-3">
            <div
              className={`${styles.custom_card} card mb-3`}
              style={{ maxWidth: "540px" }}
            >
              <div className="row no-gutters">
                <div className="col-md-4">
                  <TradeCourseModal
                    trigger={
                      <iframe
                        style={{ pointerEvents: "none" }}
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/R9DYSR9HBXs"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      //   <video
                      //     width="100%"
                      //     height="100%"
                      //     controls
                      //     id="courseVideo"
                      //     style={{ pointerEvents: "none" }}
                      //   >
                      //     <source
                      //       src="https://www.w3schools.com/html/mov_bbb.mp4"
                      //       type="video/mp4"
                      //     />
                      //   </video>
                    }
                    content={
                      //   <video
                      //     width="100%"
                      //     height="100%"
                      //     controls
                      //     id="courseVideo"
                      //   >
                      //     <source
                      //       src="https://www.w3schools.com/html/mov_bbb.mp4"
                      //       type="video/mp4"
                      //     />
                      //   </video>
                      <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/R9DYSR9HBXs"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    }
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">
                      How To Improve Performance Of Your Trading Ideas
                    </h5>
                    <p className="card-text">
                      Learn how to trade cryptocurrencies on eToro, and how to
                      find them on the platform in just a few clicks, with the
                      help of this eToro Academy tutorial.
                    </p>
                    <p className="card-text">
                      <small className="text-muted">
                        Video length 2 minutes
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TradeCoursesPage;
