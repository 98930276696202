import React from 'react';
import DrawerToggleBtn from './DrawerToggleBtn';
import { CgProfile } from "react-icons/cg";
import { FiSettings } from 'react-icons/fi';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { logOut } from '../reduxStore/actions/authAction';
import { useDispatch } from 'react-redux';


const ToolBar = (props) => {
  const dispatch = useDispatch()
  const { drawerToggleClickHandler } = props

  const logOutUser =()=>{
    dispatch(logOut());
    props.children.props.history.push('/')
  }
  return (
    <header className="toolbar">
      <nav className="toolbar__navigation">
        <div className="d-md-none d-block">
          <DrawerToggleBtn drawerToggleClickHandler={drawerToggleClickHandler} />
        </div>
        <div className="toolbar__logo">
          <a href="/pricing">OLTO</a>
        </div>
        <div className="spacer" />
        <div className="toolbar_navigation-items">
          <ul>
            {/* <li>
              <a href="/home">Feeds</a>
            </li> */}
            <li>
              <div className="dropdown">
                <div href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {/* <Avatar name="Foo Bar" size={32} round="40px" /> */}
                  <CgProfile className="toolbar_profile_icon" />
                </div>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <div className="dropdown-item d-flex align-items-center cursor_pointer" onClick={logOutUser}><RiLogoutBoxLine style={{ fontSize: "16px", marginRight: "10px" }} />Logout</div>
                  <Link to="/profile/setting">
                  <div className="dropdown-item d-flex align-items-center cursor_pointer" ><FiSettings style={{ fontSize: "16px", marginRight: "10px" }} />Setting</div>
                  </Link>
                </div>
              </div>

            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default ToolBar;