import React, { useEffect, useState } from "react";
import LoginCard from "../components/LoginCard";
import SignupCard from "../components/SignupCard";
import { useSelector } from "react-redux";
import logoImg from '../assets/images/OLTO TB - Logo.png'

const AuthPage = (props) => {
  const [active, setActive] = useState("login");
  const { user } = useSelector(({ authReducer }) => authReducer);

  useEffect(() => {
    if (user?.token) {
      props.history.push("/pricing");
    }
  }, [props, user]);

  return (
    <div className="container-fluid authBgImage position-relative">
      <div className="position-absolute" style={{top:"50px", left:"50px"}}>
        <img src={logoImg} alt="logo" className="logo_img" />
      </div>
      <div className="row h-100">
        <div className="col-md-4 mx-auto d-flex align-items-center ">
          {active === "login" && (
            <LoginCard
              {...props}
              setActive={() => {
                setActive("signup");
              }}
            />
          )}
          {active === "signup" && (
            <SignupCard
              {...props}
              setActive={() => {
                setActive("login");
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
