import Constants from "../constants/index";

let initialState = {
  err: "",
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.AUTH_ERROR:
      return {
        err: action.payload,
      };
    case Constants.ROLE:
      return {
        role: action.payload,
      };
    case Constants.USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
