import Constants from "../constants/index";
import Axios from "axios";
import { endPoints } from "../../endpoint";
import { toast } from "react-toastify";

const loginAction = (userDetails, role) => (dispatch, getstate) => {
  return Axios.post(endPoints.userLogin, userDetails, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      toast.success(`Welcome to Olto ${res.data.data.firstname}!`, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({
        type: Constants.USER_DETAILS,
        payload: res.data,
      });
      return { status: true, message: "message" };
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      return {
        status: false,
      };
    });
};

const logOut = (payload) => ({
  type: Constants.LOG_OUT,
});

const signupAction = (userDetails) => (dispatch, getstate) => {
  return Axios.post(endPoints.userRegistration, userDetails, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      console.log(res, "olto tips");
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({
        type: Constants.USER_DETAILS,
        payload: res.data,
      });
      return { status: true, message: "message" };
    })
    .catch((err) => {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      return {
        status: false,
      };
    });
};

const fetchUserDetails = () => (dispatch, getstate) => {
  const token = getstate().authReducer.user.token;
  return Axios.get(endPoints.getUserDetails, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // toast.success(``, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch({
        type: Constants.USER_DETAILS,
        payload: res.data,
      });
      return { status: true, message: "message" };
    })
    .catch((err) => {
      // toast.error(err.response.message, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      return {
        status: false,
      };
    });
};

// const getUserDetail = () => {
//   axios
//     .get(endPoints.getUserDetails, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((res) => {
//       console.log(res.data);
//       toast.success(`Welcome to Olto ${res.data.data.firstname}!`, {
//         position: toast.POSITION.TOP_CENTER,
//       });
//       dispatch({
//         type: Constants.USER_DETAILS,
//         payload: res.data,
//       });
//       props.history.push("/pricing");
//     })
//     .catch((err) => {
//       toast.error(err.response?.data.message, {
//         position: toast.POSITION.TOP_CENTER,
//       });
//     });
// };

// const selectRole = (selectedRole) => (dispatch) => {
//   dispatch({
//     type: Constants.ROLE,
//     payload: selectedRole,
//   });
// };

export { loginAction, signupAction, logOut, fetchUserDetails };
