import React from 'react';

const DrawerToggleBtn = (props) => {
    const { drawerToggleClickHandler } = props
    return (
        <button className="toggle-button" onClick={drawerToggleClickHandler}>
            <div className="toggle-button__line" />
            <div className="toggle-button__line" />
            <div className="toggle-button__line" />
        </button>
    );
};

export default DrawerToggleBtn;