import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { endPoints } from "../endpoint";
import Constants from "../reduxStore/constants";


const RedirectView = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = new URLSearchParams(props.location.search).get("token");
    const loginAction = () => {
      axios
        .get(endPoints.getUserDetails, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success(`Welcome to Olto ${res.data.data.firstname}!`, {
            position: toast.POSITION.TOP_CENTER,
          });
          dispatch({
            type: Constants.USER_DETAILS,
            payload: res.data,
          });
          props.history.push("/pricing");
        })
        .catch((err) => {
          toast.error(err.response?.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    };
    loginAction();
  }, [props.location.search, dispatch, props.history]);
  return <div>loading...</div>;
};

export default RedirectView;
